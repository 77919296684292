// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hstack{
  /*  fix fonts */
  display: flex;
  background-image: linear-gradient(to  bottom right, rgb(0, 0, 0), rgb(45, 44, 44));
  height: 100vh;
}

.elm{
  display: flex;
  align-items: center;
  margin: 0%;
  width: 50%;
  height: 100vh;
}

.vstack{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2%;
  width: 46%;
  height: 96vh;
}

.heading{
  font-size: 400%;
  color: #a2a2a2;
  
}

.subheading{
  font-size: 170%;
  color:#a2a2a2;
}

.button{
  color: black;
  background-image:linear-gradient(to right, rgb(5, 236, 55) , rgb(29, 160, 57));
  width: 75%;
  max-width: 300px;
  height: 7%;
  align-self: center;
}

.stingrays{
  /* display: none; */
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 30% 30% 30%;
}

.mobile{
  display: none;
  color: red;
}

@media screen and (max-width:1400px) {
  .stingrays{
    display: none;
  }
  .hstack{
    width: 100%;
    justify-content: center;
    
  }
  .vstack{
    width: 100%;
    
  }
  .heading{
    margin: 0%;
  }
}


@media screen and (max-width:768px) {
  .mobile{
    display: flex;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,aAAa;EACb,kFAAkF;EAClF,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,UAAU;EACV,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,UAAU;EACV,UAAU;EACV,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;;AAEhB;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,8EAA8E;EAC9E,UAAU;EACV,gBAAgB;EAChB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,kCAAkC;EAClC,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;IACX,uBAAuB;;EAEzB;EACA;IACE,WAAW;;EAEb;EACA;IACE,UAAU;EACZ;AACF;;;AAGA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["\n.hstack{\n  /*  fix fonts */\n  display: flex;\n  background-image: linear-gradient(to  bottom right, rgb(0, 0, 0), rgb(45, 44, 44));\n  height: 100vh;\n}\n\n.elm{\n  display: flex;\n  align-items: center;\n  margin: 0%;\n  width: 50%;\n  height: 100vh;\n}\n\n.vstack{\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  margin: 2%;\n  width: 46%;\n  height: 96vh;\n}\n\n.heading{\n  font-size: 400%;\n  color: #a2a2a2;\n  \n}\n\n.subheading{\n  font-size: 170%;\n  color:#a2a2a2;\n}\n\n.button{\n  color: black;\n  background-image:linear-gradient(to right, rgb(5, 236, 55) , rgb(29, 160, 57));\n  width: 75%;\n  max-width: 300px;\n  height: 7%;\n  align-self: center;\n}\n\n.stingrays{\n  /* display: none; */\n  display: grid;\n  grid-template-columns: 30% 30% 30%;\n  grid-template-rows: 30% 30% 30%;\n}\n\n.mobile{\n  display: none;\n  color: red;\n}\n\n@media screen and (max-width:1400px) {\n  .stingrays{\n    display: none;\n  }\n  .hstack{\n    width: 100%;\n    justify-content: center;\n    \n  }\n  .vstack{\n    width: 100%;\n    \n  }\n  .heading{\n    margin: 0%;\n  }\n}\n\n\n@media screen and (max-width:768px) {\n  .mobile{\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
